import LOGO from '../assets/logo.jpg'

type ImageType = string

const IMAGES: {
	LOGO: ImageType
} = {
	LOGO,
}
export default IMAGES
